import React from "react";
import PropTypes from "prop-types";
import NextLink from "next/link";
import cx from "classnames";
import { UilArrowRight as ArrowRight } from "@iconscout/react-unicons";

const LINK_TYPE_BUTTON = "button";
const LINK_TYPE_ICON = "nextArrowIcon";

export const getPageLink = (page) => {
  switch (page?.type || page?._type) {
    case "quiz":
      return page?.slug === "onboarding" ? "/onboarding/1" : `/quiz/${page?.slug || ""}`;
    case "story":
      return `/stories/${page?.slug || ""}`;
    case "guide":
      return `/guides/${page?.slug || ""}`;
    case "market":
      return `/markets/${page?.slug || ""}`;
  }
  return page?.slug;
};

export const getPageTo = (to) => (to.startsWith("/") || to.startsWith("http") ? to : `/${to}`);

const Link = ({
  url,
  page,
  title,
  linkType,
  isInternal = false,
  newWindow = false,
  shouldScroll = false,
  children,
  className,
}) => {
  let to = isInternal ? getPageLink(page) : url;

  if (!to) {
    return null;
  }

  to = getPageTo(to);

  return (
    <NextLink passHref scroll={shouldScroll} href={to} as={to}>
      <a
        title={title}
        target={newWindow ? "_blank" : "_self"}
        className={
          linkType === "link"
            ? cx("underline underline-offset-4", className)
            : cx(
                "btn",
                {
                  "mt-2": !linkType || linkType === LINK_TYPE_BUTTON,
                },
                className
              )
        }
      >
        {linkType === LINK_TYPE_ICON ? <ArrowRight className="text-white" /> : title || children}
      </a>
    </NextLink>
  );
};

Link.propTypes = {
  url: PropTypes.string,
  page: PropTypes.shape({
    slug: PropTypes.string,
  }),
  title: PropTypes.string,
  children: PropTypes.node,
  newWindow: PropTypes.bool,
  isInternal: PropTypes.bool,
  shouldScroll: PropTypes.bool,
  linkType: PropTypes.oneOf(["link", "button", "nextArrowIcon"]),
};

export default Link;
