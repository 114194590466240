import React, { useState, useEffect, useRef } from "react";
import cx from "classnames";
import PropTypes from "prop-types";
import { useWindowSize } from "react-use";
import Picture from "components/picture";
import CoreContainer from "../../core-container";
import BlockContent from "../../blocks/block-content";
import { UilAngleDown as ArrowDownIcon } from "@iconscout/react-unicons";
import "./hero-grid.css";

const HeroGrid = ({ data = {} }) => {
  const { title, slide, content, theme, mobileOverlayContent, bgGradient } = data;
  const selectedTheme = theme || "light";
  const textAlign = "center";
  const [currentRoom, setCurrentRoom] = useState(" ");
  const [activeRow, setActiveRow] = useState(0);
  const windowSize = useWindowSize();
  const ref = useRef(null);
  const [isBrowserReady, setIsBrowserReady] = useState(false);
  useEffect(() => {
    if (windowSize && windowSize.width > 0) {
      setIsBrowserReady(true);
    }
  }, [windowSize]);
  return (
    <>
      <section
        className={cx(
          "hero-grid-module relative mx-auto hidden h-[640px] flex-col justify-start overflow-hidden text-center sm:h-screen sm:min-h-[780px] sm:justify-start md:-mt-28 md:flex",
          textAlign ? `text-position--${textAlign}` : null,
          selectedTheme ? `theme theme--${selectedTheme}` : null
        )}
        ref={ref}
      >
        <div className="hero--grid--cover"></div>
        {slide && isBrowserReady && windowSize.width >= 768 && (
          <>
            <CoreContainer>
              <div
                className={cx(
                  " relative z-10 mt-28 flex h-[calc(30vh+150px)] flex-col justify-end whitespace-pre-wrap text-mh4 md:text-dh5 lg:min-h-[348px]",
                  {
                    ["md:text-left"]: textAlign === "left",
                    ["md:text-right"]: textAlign === "right",
                    ["mx-auto max-w-5xl md:text-center"]: textAlign === "center",
                  }
                )}
              >
                <div className="">
                  <HeaderText title={title} activeRow={activeRow} rooms={slide} />
                  {content && (
                    <div className="m-auto max-w-[420px]">
                      <BlockContent blocks={content} />
                    </div>
                  )}
                  <div className="mt-7 flex justify-center">
                    {slide.map((data, index) => {
                      return (
                        <div
                          key={index}
                          onClick={() => {
                            if (activeRow !== index) {
                              setActiveRow(index);
                              setCurrentRoom(data.title);
                            }
                          }}
                          className={cx("mx-1 h-2.5 w-2.5 flex-shrink-0 cursor-pointer rounded-full bg-accent-200", {
                            ["!bg-accent-700"]: activeRow === index,
                          })}
                        ></div>
                      );
                    })}
                  </div>
                </div>
              </div>
            </CoreContainer>
            <ImageCards
              activeRow={activeRow}
              setActiveRow={setActiveRow}
              currentRoom={currentRoom}
              handleCurrentRoom={setCurrentRoom}
              rooms={slide}
            />
          </>
        )}

        <button
          onClick={() => {
            const elementPosition = ref.current.getBoundingClientRect().top + window.scrollY;

            window.scrollTo({
              behavior: "smooth",
              top: elementPosition + ref.current.clientHeight - 60,
              left: 0,
            });
          }}
          className="absolute left-0 right-0 bottom-[22px] z-10 mx-auto hidden h-12 w-12 animate-bouncing-arrow items-center justify-center rounded-full bg-[#E5B04F] md:flex"
        >
          <ArrowDownIcon className="h-11 w-11 text-neutral-100" />
        </button>
      </section>

      <CoreContainer className="hero-module relative z-[1] flex h-[calc(100vh-80px)] max-h-[626px] items-end  md:hidden">
        <div className="pointer-events-none absolute bottom-0 z-[-1] grid h-screen max-h-[706px] w-full grid-cols-3 gap-y-1 gap-x-1 overflow-y-hidden bg-black px-1">
          <div
            className={cx(
              "hero--cover",
              "bg-[#0B1A19] opacity-[0.65]",
              bgGradient === "white" ? "hero--cover--bg-gradient-white" : null
            )}
          ></div>
          <div className="hero--bottom--gradient"></div>
          {slide &&
            slide?.[0]?.slideImages?.map((photo, index) => (
              <div key={index} className="relative">
                <Picture
                  picture={{ image: photo }}
                  layout="responsive"
                  sizes="(max-width: 768px) 33.3vw, 0vw"
                  quality={index < 9 ? 50 : 30}
                  className="rounded-xl"
                  objectFit="cover"
                />
              </div>
            ))}
        </div>

        <div
          className={cx("mobile--block--content", {
            ["text-left"]: textAlign === "left",
            ["text-right"]: textAlign === "right",
            ["text-center"]: textAlign === "center",
          })}
        >
          <BlockContent blocks={mobileOverlayContent} />
        </div>
      </CoreContainer>
    </>
  );
};

const CardColumn = ({ className = "", itemClassName = "", photos }) => {
  return (
    <div className={cx("card-column", className)}>
      {photos?.map((photo, index) => (
        <div key={index} className={cx("card-item", itemClassName)}>
          <Picture
            picture={{ image: photo }}
            layout="fixed"
            width={236}
            quality={75}
            height={350}
            className="rounded-xl"
            objectFit="cover"
          />
        </div>
      ))}
    </div>
  );
};

const HeaderText = ({ title, rooms, activeRow }) => {
  return (
    <div className="mb-[90px] flex flex-col items-center font-normal !text-[#211922] sm:text-4xl md:text-6xl">
      {title}
      <div className="wrapper">
        {rooms?.map((room, index) => {
          return <RoomText key={index} currentIndex={index} text={room.title} activeRow={activeRow} />;
        })}
      </div>
    </div>
  );
};

const RoomText = ({ text, currentIndex, activeRow }) => {
  return (
    <div
      className={cx(
        "text-offset transition-opacity",
        currentIndex === activeRow ? "opacity-100 delay-[1300ms]" : "opacity-0 delay-[900ms]"
      )}
    >
      <div className={cx("text text-accent-500", currentIndex === activeRow ? "animate-text-in" : "animate-text-out")}>
        {text}
      </div>
    </div>
  );
};

const CardRow = ({ itemClassName = "", rowIndex, data, currentIndex }) => {
  const [animationClassName, setAnimationClassName] = useState("animate-before");
  const isActive = currentIndex === rowIndex;
  useEffect(() => {
    if (isActive) {
      setAnimationClassName("");
      return;
    }
    setAnimationClassName("animate-after");
  }, [isActive]);
  return (
    <div className={cx("card-inner", isActive ? "active" : "")}>
      <CardColumn
        photos={data.slideImages.slice(0, 3)}
        className={cx("mt-0", animationClassName)}
        itemClassName={itemClassName}
      />
      <CardColumn
        photos={data.slideImages.slice(3, 5)}
        className={cx(" mt-[160px]", animationClassName)}
        itemClassName={itemClassName}
      />

      <CardColumn
        photos={data.slideImages.slice(5, 7)}
        className={cx("mt-[240px]", animationClassName)}
        itemClassName={itemClassName}
      />
      <CardColumn
        photos={data.slideImages.slice(7, 9)}
        className={cx("mt-[400px]", animationClassName)}
        itemClassName={itemClassName}
      />
      <CardColumn
        photos={data.slideImages.slice(9, 11)}
        className={cx("mt-[240px]", animationClassName)}
        itemClassName={itemClassName}
      />

      <CardColumn
        photos={data.slideImages.slice(11, 13)}
        className={cx("mt-[160px]", animationClassName)}
        itemClassName={itemClassName}
      />
      <CardColumn
        photos={data.slideImages.slice(13, 16)}
        className={cx("mt-0", animationClassName)}
        itemClassName={itemClassName}
      />
    </div>
  );
};

const ImageCards = ({ rooms, handleCurrentRoom, activeRow, setActiveRow }) => {
  const startInterval = () => {
    const nextRow = activeRow === rooms?.length - 1 ? 0 : activeRow + 1;
    const roomKeys = rooms?.map((x) => x.title);
    const nextRoom = roomKeys?.[nextRow];

    handleCurrentRoom(nextRoom);
    setActiveRow(nextRow);
  };
  useEffect(() => {
    const interval = setInterval(() => {
      startInterval();
    }, 4000);
    return () => {
      clearInterval(interval);
    };
  }, [activeRow]);

  return (
    <div className="card-container">
      {rooms?.map((data, index) => {
        return <CardRow key={index} rowIndex={index} data={data} currentIndex={activeRow} />;
      })}
    </div>
  );
};

HeroGrid.propTypes = {
  data: PropTypes.shape({
    title: PropTypes.string,
    content: PropTypes.any,
    slide: PropTypes.arrayOf(
      PropTypes.shape({
        title: PropTypes.string,
        slideImages: PropTypes.arrayOf(
          PropTypes.shape({
            image: PropTypes.shape({}),
          })
        ),
      })
    ),
  }),
};

export default HeroGrid;
